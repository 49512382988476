.Site-Hero {
  width: 100%;
  height: 550px; }
.SiteHero-Container {
  display: grid;
  grid-template-columns: 1fr max-content;
  height: 550px;
  .Offer-Container {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top -100px right 0px;
    display: flex;
    align-items: center; }
  .Offer-Slider {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%; } }

  .Offer-SocialBlock {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--accent);
    padding: 0px 64px;
    a {
      &:hover {
        .Icon {
          color: var(--lightGray) !important; } }
      &:not(:last-child) {
        margin-bottom: 16px; } }
    .Icon {
      color: #fff;
      font-size: 24px; } }

  .Offer {
    background-color: rgba(48,48,48, .7);
    padding: 32px 64px; }
  .Offer-Content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px; }
  .Offer-Title {
    font-size: 48px;
    color: #fff;
    font-family: var(--accentFont);
    font-weight: 300;
    line-height: 58px;
    .Bolder {
      font-weight: 500; } }
  .Offer-Description {
    font-family: var(--activeFont);
    font-weight: 400;
    font-size: 22px;
    color: #fff; }
  .Offer-Buttons {
    justify-self: start;
    a {
      &:first-child {
        margin-right: 16px; } } } }

@media screen and ( max-width: 1024px ) {
  .SiteHero-Container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    .Offer-SocialBlock {
      flex-direction: row;
      padding: 24px;
      a {
        &:hover {
          .Icon {
            color: var(--lightGray) !important; } }
        &:not(:last-child) {
          margin-bottom: 0px;
          margin-right: 16px; } } } } }
@media screen and ( max-width: 768px ) {
  .Site-Hero {
    width: 100%;
    height: 100%; }
  .SiteHero-Container {
    height: 100%;
    .Offer-Title {
      font-size: 36px;
      line-height: 46px; }
    .Offer-Description {
      font-size: 18px; }
    .Offer-SocialBlock {
      padding: 16px;
      .Icon {
        font-size: 18px; } } } }

@media screen and ( max-width: 576px ) {
  .SiteHero-Container {
    .Offer {
      padding: 64px 16px; } } }
