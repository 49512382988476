.SectionTitle {
  font-size: 24px;
  line-height: 34px;
  font-weight: 400;
  color: var(--black);
  display: inline-block;
  font-family: var(--activeFont);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 64px;
  .Decorator {
    width: 64px;
    height: 3px;
    background-color: var(--accent);
    margin-left: 16px; } }
.SectionTitle_theme_white {
  color: #fff;
  .Decorator {
    background-color: #fff; } }

@media screen and ( max-width: 768px ) {
  .SectionTitle {
    margin-bottom: 32px; } }
