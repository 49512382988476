.ContactUs {
  background-color: #fff;
  .SocialIcons {
    font-size: 18px;
    .Icon {
      &:not(:last-child) {
        margin-right: 16px; } } } }
.ContactUs-Container {
  margin-top: 64px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 64px;
  .ContactUs-Links {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 24px; }
  .SocialIcons {
    font-size: 18px;
    a {
      &:hover {
        color: var(--sea); }
      &:not(:last-child) {
        margin-right: 12px; } } }
  .ContactUs-Map {
    margin-top: 64px;
    height: 350px;
    .Map {
      height: 350px; } } }

.ContactUsInfoTile {
  display: flex;
  align-items: center;
  .ContactUsInfoTile-Icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    background-color: var(--accent);
    margin-right: 12px; }
  .ContactUsInfoTile-Data {
    font-size: 18px;
    font-family: var(--activeFont);
    a {
      color: #000; }
    .Description {
      font-size: 14px;
      font-family: var(--defaultFont);
      color: #000; } } }

@media screen and ( max-width: 1200px ) {
  .ContactUs-Container {
    grid-template-columns: 1fr;
    .ContactUs-Info {
      order: 1; }
    .ContactUs-Form {
      max-width: 100%;
      order: 0; } } }

@media screen and ( max-width: 576px ) {
  .ContactUs-Container {
    .ContactUs-Links {
      grid-template-columns: 1fr; }
    // .ContactUs-Form
 } }    //   justify-items: center
