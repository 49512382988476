.InfoLine {
  display: flex;

  font-size: 14px;
  line-height: 24px;
  color: var(--black);
  .Icon {
    margin-top: 2px;
    font-size: 24px;
    margin-right: 8px;
    color: var(--accent); }
  .Bolder {
    margin-right: 8px;
    font-weight: 500; } }
