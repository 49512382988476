.PhoneChooser {
  display: flex;
  align-items: center;
  color: var(--accent);
  .ChooserIcon {
    color: var(--accent); }
  .Icon {
    font-size: 18px; }
  .PhoneChooser-ActivePhone {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 40000;
    font-family: var(--activeFont);
    margin: 0px 8px 0px 12px; } }
