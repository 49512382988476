.Site-Nav {
  width: 100%;
  height: 64px;
  background-color: #fff;
  padding: 0px 48px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 32px;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  transition: all 0.4s ease;
  .Logo {
    flex-shrink: 0; } }
.Site-Nav_theme_shadow {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07); }
.Site-Nav-Menu {
  display: grid;
  grid-template-columns: repeat(8, max-content);
  grid-gap: 32px;
  align-items: center;
  justify-self: end; }
.Nav-Divider {
  height: 24px;
  width: 1px;
  background-color: var(--lightGray); }

.Site-NavLink {
  font-family: var(--activeFont);
  font-size: 14px;
  text-transform: uppercase;
  color: var(--black);
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  font-weight: 400;
  &:hover {
    color: var(--accent); }
  svg {
    transition: all 0.3s ease; } }
.Site-NavLink_theme_active {
 }  // color: var(--accent)

.Site-NavLink-Arrow {
  font-size: 14px;
  color: var(--descriptionDark);
  margin-left: 8px; }
.Site-Nav-Controlls {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 40px;
  align-items: center;
  justify-self: end;
  .Site-NavLink {
    padding: 0; } }
.CallUsBar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;
  .Icon {
    color: var(--accent);
    font-size: 18px;
    margin-right: 8px;
    transform: rotate(-45deg); } }
.CallUsBar-Description {
  color: var(--darkGray); }
.CallUsBar-PhoneNumber {
  font-weight: 500;
  color: #000;
  font-size: 16px;
  display: flex;
  align-items: center;
  &:hover {
    color: var(--darkGray); } }
.MobileContacts {
  display: none; }

@media screen and ( max-width:  1024px) {
  .MobileContacts {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 24px; }
  .Site-Nav {
    padding: 0px 24px;
    grid-template-columns: max-content 1fr max-content;
    grid-gap: 24px;
 }    // top: 32px
  .Site-Hamburger-Container {
    flex-shrink: 0; }
  .Site-Nav-Menu {
    display: none;
    position: absolute;
    top: 64px;
    left: 0px;
    grid-template-columns: 1fr;
    width: 100%;
    background-color: var(--sea);
    padding: 16px 24px;
    overflow-y: auto;
    max-height: calc(100vh - 64px);
    height: auto;
    grid-template-rows: repeat(5, minmax(0, max-content));
    grid-gap: 8px;
    .Site-NavLink {
      color: #fff;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px var(--lightGray) solid;
      font-size: 16px;
      padding: 8px 0px;
      &:hover {
        background: none; }
      &:last-child {
        border-bottom: 0; } }
    .Site-NavLink_theme_active {
      color: var(--lightGray);
      background: none; }
    .Nav-Divider, .PhoneChooser, .LangChooser {
      display: none; } } }

@media screen and ( max-width:  576px) {
  .MobileContacts {
    display: grid;
    grid-template-columns: max-content;
    .PhoneChooser {
      display: none; } }
  .Site-Nav {
    padding: 0px 16px; }
  .Site-Nav-Menu {
    padding: 16px;
    .PhoneChooser {
      display: block;
      color: #fff;
      .Icon {
        color: var(--black); } } } }
