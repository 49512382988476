.Carousel {
  position: relative; }
.Carousel-DotsContainer {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 12px;
  .Carousel-Dot {
    width: 12px;
    height: 12px;
    background-color: var(--lightGray);
    opacity: 0.5;
    cursor: pointer;
    appearance: none;
    outline: none;
    display: block;
    &:not(:last-child) {
      margin-right: 8px; }
    &:hover {
      opacity: 1 !important;
      background-color: var(--accent); } }
  .glide__bullet--active {
    background-color: #fff;
    opacity: 1; } }
.Carousel-Arrow {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  border: 0px;
  background-color: var(--black);
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  .Icon {
    font-size: 24px; }
  svg {
    color: #fff; }
  &:hover {
    background-color: rgba(0,0,0,.6); } }
.Arrow-Left {
  left: -16px; }
.Arrow-Right {
  right: -16px; }
