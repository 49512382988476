.Numbers-Container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 64px; }
.ExtraServices {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  align-self: start;
  .Title {
    font-family: var(--activeFont);
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px; } }

.NumberTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
  &:hover {
    cursor: pointer;
    box-shadow: var(--boxShadow); }

  .NumberTile-InfoContainer {
    padding: 16px; }
  .Title {
    font-family: var(--activeFont);
    font-size: 18px;
    text-transform: uppercase;
    color: var(--black);
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px; }
  .Button {
    margin-top: auto; } }

.NumbersCarousel {
  width: 300px;
  height: 200px;
  img {
    width: 100%;
    object-fit: cover;
    height: 200px;
    object-position: center center; } }

@media screen and ( max-width:  1024px) {
  .Numbers-Container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px; } }

@media screen and ( max-width:  768px) {
  .Numbers-Container {
    grid-template-columns: 1fr;
    grid-gap: 32px; } }
