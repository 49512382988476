.LangChooser {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  .ChooserIcon {
    color: var(--accent); }
  .Icon {
    font-size: 18px; }
  .LangChooser-ActiveLang {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: var(--activeFont);
    margin: 0px 8px 0px 12px; }
  .LangChooser-Container {
    min-width: 48px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
    padding: 8px 0px;
    position: absolute;
    top: 42px;
    right: 0; }
  .LangChooser-Lang {
    text-align: center;
    padding: 4px 8px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: var(--activeFont);
    cursor: pointer;
    &:hover {
      background-color: var(--sea);
      color: #fff; } } }
